/** @jsx jsx */
import { jsx } from "theme-ui"
import { useEffect, useState } from "react"
import { Link as GLink } from "gatsby"
import { Flex, Box, Link, Avatar, Text, Button } from "theme-ui"
import { ic_menu } from "react-icons-kit/md/ic_menu"
import { ic_close } from "react-icons-kit/md/ic_close"
import { ic_language } from "react-icons-kit/md/ic_language"
import Icon from "react-icons-kit"
import LightSpeed from "react-reveal/LightSpeed"
import Scrollspy from "react-scrollspy"
import { useLocation } from "@reach/router"
import useLanguage from "../hooks/useLanguage"
import icon from "../images/logo_icon.png"
import useNavigationsQuery from "../hooks/useNavigationsQuery"

const isBrowser = typeof window !== undefined

const Header = () => {
  const { site } = useNavigationsQuery()
  const lang = useLanguage()
  const [scrollY, setScrollY] = useState(0)
  const [isOpen, setIsOpen] = useState(false)
  const isEn = useLanguage() === "en"

  const isProductsPage = !!useLocation().pathname.includes("/products/")
  const path = isProductsPage ? "/products/" : "/"

  function logit() {
    if (isBrowser) setScrollY(window.pageYOffset)
  }

  useEffect(() => {
    if (isBrowser) {
      function watchScroll() {
        window.addEventListener("scroll", logit)
      }
      watchScroll()
      return () => {
        window.removeEventListener("scroll", logit)
      }
    }
  })

  useEffect(() => {
    if (isBrowser) {
      const mobileMenu = document.querySelector(".mobile-menu")
      const main = document.querySelector("main")
      main.addEventListener(
        "click",
        event => {
          if (mobileMenu === event.target) return
          setIsOpen(false)
        },
        false
      )
    }
  })

  return (
    <Box
      as="header"
      sx={{
        position: "fixed",
        width: "100%",
        top: 0,
        bg: scrollY > 0 ? "background" : "transparent",
        py: scrollY > 0 ? "0.5rem" : "18px",
        px: ["5px", "1rem"],
        boxShadow: scrollY > 0 ? "0 0 4px #333" : "unset",
        transition: "all .4s ease",
        zIndex: 1000,
      }}
    >
      <Flex
        sx={{
          justifyContent: "space-between",
          alignItems: "center",
          maxWidth: 1860,
          m: "0 auto",
        }}
      >
        <Flex sx={{ alignItems: "center" }}>
          <Flex sx={{ alignItems: "center" }}>
            <Avatar variant="logo" src={icon} sx={{ marginInlineEnd: 10 }} />
            <Box>
              <Text
                as="p"
                variant="small"
                sx={{ fontSize: ["11px", null, "14px"], fontWeight: 600 }}
                color={scrollY > 0 ? "text" : "background"}
              >
                بـــن دارانـــــي بــاوزيـــر للتـجـــــارة
              </Text>
              <Text
                as="p"
                color="primaryDark"
                variant="small"
                sx={{ fontSize: ["11px", "11px", "14px"], fontWeight: 600 }}
              >
                Bin Darany Bawazeer For Trading
              </Text>
            </Box>
          </Flex>

          <Flex
            sx={{
              display: ["none", "none", "none", "none", "flex"],
              marginInlineStart: "50px",
            }}
          >
            <Scrollspy
              items={["home", "about", "contact"]}
              currentClassName="active"
            >
              {site.siteMetadata.navigations.map((item, i) =>
                !item.isBtn ? (
                  <Link
                    key={i}
                    as={GLink}
                    to={`${lang === "ar" ? "/ar" : ""}${item.path}`}
                    variant="nav"
                    color={scrollY > 0 ? "secondary" : "background"}
                  >
                    {item[`title_${lang}`]}
                  </Link>
                ) : (
                  <Button
                    key={i}
                    variant="navStyle"
                    color={scrollY > 0 && "primary"}
                    as={GLink}
                    to={`${lang === "ar" ? "/ar" : ""}${item.path}`}
                    sx={{
                      marginInlineStart: 40,
                      ...(isProductsPage && {
                        bg: "primary",
                        color: "white",
                        ":hover": {
                          color: "white",
                        },
                      }),
                    }}
                    activeClassName="active"
                  >
                    {item[`title_${lang}`]}
                  </Button>
                )
              )}
            </Scrollspy>
          </Flex>
        </Flex>
        <Flex sx={{ alignContent: "center", position: "relative" }}>
          <Flex
            as={GLink}
            to={isEn ? "/ar" + path : path}
            sx={{
              color: "secondary",
              p: "5px 6px",
              fontSize: 12,
              fontWeight: 600,
              textDecoration: "none",
            }}
          >
            <Text sx={{ marginInlineEnd: 2 }}>{isEn ? "عربي" : "English"}</Text>
            <Icon icon={ic_language} size={20} />
          </Flex>
          <Flex
            sx={{
              cursor: "pointer",
              display: [null, null, null, null, "none"],
              marginInlineStart: 5,
              bg: scrollY > 0 ? "text" : "background",
              color: scrollY > 0 ? "background" : "text",
              borderRadius: 3,
              px: "3px",
            }}
            onClick={() => setIsOpen(prev => !prev)}
          >
            <Icon icon={isOpen ? ic_close : ic_menu} size={25} />
          </Flex>
          {/* Mobil Menu */}
          <Box
            className="mobile-menu"
            sx={{
              bg: "background",
              position: "absolute",
              width: "200px",
              p: "10px",
              display: isOpen ? "block" : "none",
              top: "40px",
              left: isEn ? "unset" : 0,
              right: !isEn ? "unset" : 0,
              borderRadius: "3px",
            }}
          >
            <LightSpeed when={isOpen}>
              {site.siteMetadata.navigations.map((item, i) => (
                <Link
                  key={i}
                  as={GLink}
                  to={`${lang === "ar" ? "/ar" : ""}${item.path}`}
                  variant="nav"
                  sx={{
                    display: "block",
                    m: 0,
                  }}
                >
                  {item[`title_${lang}`]}
                </Link>
              ))}
            </LightSpeed>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

Header.propTypes = {}

Header.defaultProps = {}

export default Header
