
import { useStaticQuery, graphql } from "gatsby"

export default function useNavigationsQuery() {
  return useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            navigations {
              title_ar
              title_en
              val
              isBtn
              path
            }
          }
        }
      }
    `
  )
}