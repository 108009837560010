/** @jsx jsx */
import { jsx } from "theme-ui"
import useLanguage from "../hooks/useLanguage"

export default function Footer() {
  const isAR = useLanguage() === "ar"
  const footerText = isAR
    ? "جميع الحقوق محفوظة © بن داراني باوزير"
    : "All rights reserved © Bin Darany Bawazeer"
  return (
    <footer
      sx={{
        backgroundColor: "secondary",
        color: "white",
        textAlign: "center",
      }}
    >
      {`${footerText} ${new Date().getFullYear()}`}
    </footer>
  )
}
